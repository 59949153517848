import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../../payroll/services/login.service';
import { Router } from '@angular/router';
import { ActualUserService } from '../../payroll/services/user.service';
import { User } from '../../payroll/models/user.model';
import { LayoutUtilsService, MessageType } from '../../payroll/utils/layout-utils.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { PayrollNgxService } from '../../payroll/services/payroll-ngx.service';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { AccessData } from '../../../../core/auth/access-data';

@Component({
  selector: 'm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  validUser: boolean;
  networkError: boolean;
  isValid: boolean;
  loading: boolean;
  idrol: number;
  usernameList: any;
  badUsername: boolean;
  badPassword: boolean;

  constructor(private loginService: LoginService, private router: Router,
    private userService: ActualUserService,
    private layoutUtilsService: LayoutUtilsService,
    private spinner: NgxSpinnerService,
    private detRef: ChangeDetectorRef,
    private ngxService: PayrollNgxService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginService.currentSpinner.subscribe(value => {
      if (value) {
        this.spinner.hide();
      }
    });
    this.isValid = true;
    this.validUser = true;
    this.networkError = false;
    this.loading = false;
    //await this.getUsers();
    console.log('this.usernameList', this.usernameList)
  }

  first = true;

  // async getUsers(){
  //   await this.userService.authorizedUser().subscribe(result => {
  //     console.log('authorizedUserService',result);
  //     this.usernameList = result;
  //   });
  // }

  resetColor() {
    this.badUsername = false;
    this.badPassword = false;
    this.detRef.detectChanges();
  }

  authorized(users, username) {
    console.log('users', users)
    for (let user of users)
      if (user.email == username)
        return user;
    return false;
  }

  logIn(username: string, password: string, event: Event) {
    event.preventDefault();

    if (username == '') {
      this.badUsername = true;
      this.detRef.detectChanges();
    }

    if (password == '') {
      this.badPassword = true;
      this.detRef.detectChanges();
    }

    this.userService.authorizedUser().subscribe(r => {
      let user = this.authorized(r, username);
      if (user && (username != '' && password != '')) {
        this.loading = true;
        this.loginService.login(username, password).subscribe(
          res => {console.log('resssssss',res);
            if (res != 'undefined') {
              let accData: AccessData = {
                id: res['user'].id,
                username: username,
                firstname: res['user'].firstname,
                lastname: res['user'].lastname,
                permissions: user.permissions.toUpperCase(),
                accessToken: res['token'],
                refreshToken: res['token'],
                roles: [res['user'].role]

              };
              console.log('res[perm].permisos.toUpperCasse()', accData.permissions,accData.roles);

              this.userService.setUserLoggedIn(accData);
              // this.authenticationService.nodeAuth(res['user'].id);
              this.authenticationService.saveAccessData2(accData)
              this.ngxService.testingNgxPermissions();
              location.href = '/payroll';
            }

          },
          (error: HttpErrorResponse) => {
            // if(this.first){
            //   this.logIn(username,password,event);
            //   this.first = false;
            // }
            if (error.statusText == "Unauthorized") {
              this.isValid = false;
              this.loading = false;
              this.detRef.detectChanges();

              setTimeout(() => {
                this.isValid = true;
                this.detRef.detectChanges();
              }, 5000, "JavaScript");

            }
            if (error.statusText == "Unknown Error") {
              this.networkError = true;
              this.loading = false;
              this.detRef.detectChanges();

              setTimeout(() => {
                this.networkError = false;
                this.detRef.detectChanges();
              }, 5000, "JavaScript");

            }
            //console.log('this.status',this.status, this.isValid);
            console.error(error);
            //window.alert('User or Password Invaid');
            //this.layoutUtilsService.showActionNotification('User or Password Invalid', MessageType.Update, 5000, false, false); 

          }

        );
        this.userService.nodeServerAuth(username).subscribe(t => console.log('ttttttttt',t));
      }
      else {
        if (username != '' && password != '') {
          this.validUser = false;
          this.loading = false;
          this.detRef.detectChanges();

          setTimeout(() => {
            this.validUser = true;
            this.detRef.detectChanges();
          }, 5000, "JavaScript");
        }
      }
    });    

  }

  navigate() {
    this.router.navigateByUrl('../payment_report');
  }

}
