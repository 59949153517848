import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/': {
				page: {
					title: 'Dashboard',
					desc: 'Latest updates and statistic charts'
				}
			},
			'employees': { // <= add page URL
				page: { title: 'Employees', desc: 'List of Employees' } 
			},
			'payment_report': { // <= add page URL
				page: { title: 'Payment report', desc: 'Payment report' } 
			},
			'count_book': { // <= add page URL
				page: { title: 'Count book', desc: 'Count book' } 
			},
			'settings': { // <= add page URL
				page: { title: 'Settings', desc: 'Settings' } 
			},
			profile: {
				page: { title: 'User Profile', desc: '' }
			},
			'backup_and_restore': { // <= add page URL
				page: { title: 'Backup and Restore', desc: 'Backup and Restore' } 
			},
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
