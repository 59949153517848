import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs/';
import { mergeMap } from 'rxjs/operators';
import { QueryResultsModel } from '../query-models/query-results.model';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../utils/http-utils.service';
import { WeekModel } from '../models/week.model';
import { environment } from '../../../../../environments/environment';


const API_WEEK_URL = environment.protocol +'://'+environment.host+':'+environment.port+'/payroll';
const cUser = environment.user;


@Injectable({
  providedIn: 'root'
})
export class WeekService {

  constructor(private http: HttpClient,private httpUtils: HttpUtilsService) { }

  generateAllWeeksChange(){
    let value = '';
      value = localStorage.getItem('allWeeks') == 'false'? 'true': 'false';
      localStorage.setItem('allWeeks',value);
    //let url = API_WEEK_URL+'/generate_all_weeks_change';
    //return this.http.put(url,{});
  }

  generateAllWeeksOff(){
    localStorage.setItem('allWeeks','false');
    // let url = API_WEEK_URL+'/generate_all_weeks_off';
    // return this.http.put(url,{});
  }

  generateAllWeeksView(){
    return localStorage.getItem('allWeeks');
    // let url = API_WEEK_URL+'/generate_all_weeks_view';
    // return this.http.get(url);
  }

  getAllYears():Observable<QueryResultsModel>{

    const httpHeaders = this.httpUtils.getHTTPHeaders();
    let url = API_WEEK_URL+'/weeks/years';
    return this.http.get<any>(url,{
      headers: httpHeaders
    }).pipe(
			mergeMap(res => {
        const queryResults = new QueryResultsModel();
        queryResults.items = res.items;         
        of(queryResults).subscribe(x => x);       
				return of(queryResults);
			})
		);
  }

  getWeekYear(agYear: number):Observable<QueryResultsModel>{
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    if(agYear == undefined){
      agYear = new Date().getFullYear();
    }
    let url = API_WEEK_URL+'/weeks/'+agYear+'/'+localStorage.getItem('allWeeks');
    
    return this.http.get<any>(url,{
      headers: httpHeaders
    }).pipe(
			mergeMap(res => {console.log('CARNE DE res',res)
        const queryResults = new QueryResultsModel();
        queryResults.items = res.items;    
        queryResults.currentWeek = res.currentWeek;  
        queryResults.weekClose = res.weekClose;  
        //console.log("AAAAA"+res.items);       
				return of(queryResults);
			})
		);
  }

  getAllWeekYear(agYear: number):Observable<QueryResultsModel>{
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    
    if(agYear == undefined){
      agYear = new Date().getFullYear();
    }
    let url = API_WEEK_URL+'/weeks/all/'+agYear;
    
    return this.http.get<any>(url,{
      headers: httpHeaders
    }).pipe(
			mergeMap(res => {
        const queryResults = new QueryResultsModel();
        queryResults.items = res.items;    
        queryResults.currentWeek = res.currentWeek;  
        queryResults.weekClose = res.weekClose;  
        //console.log("AAAAA"+res.items);       
				return of(queryResults);
			})
		);
  }

  isCLoseWeek(week: Number): Observable<any>{
    let url = API_WEEK_URL+'/weeks/close/'+week;
    return this.http.get(url);
  }
  
  getLimitsWeek(week: Number): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    let url = API_WEEK_URL+'/weeks/limit';
    url = `${url}/${week}`;
		return this.http.get<any>(url,{ headers: httpHeaders});
  }

  generateWeeksYear(week: WeekModel){
    	// Note: Add headers if needed (tokens/bearer)
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      let url = API_WEEK_URL+'/weeks/generate/' + cUser;
      return this.http.post<any>(url, week, { headers: httpHeaders});
  }

  deleteWeek(idWeek: number): Observable<WeekModel> {
    let url = API_WEEK_URL+'/weeks';
		url = `${url}/${idWeek}`;
		return this.http.delete<WeekModel>(url);
  }

  deleteYear(year: number): Observable<WeekModel> {
    let url = API_WEEK_URL+'/weeks/year';
		url = `${url}/${year}/${cUser}`;
		return this.http.delete<WeekModel>(url);
  }
}
