// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			EMPLOYEES: 'Employees',
			PAYMENT:'Payment report',
			SETTINGS: 'Settings',
			COUNT:'Count book',
			BACKUP:'Backup and Restore',
			PAYMENT_REPORT: 'Payment Report Resume',
			USER_LOG: 'User Log',
			EMPLOYEE_CREDITS: 'Employee Deductions',
			CREDITS_CONCEPT: 'Deductions by Concept'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect'
			}
		},
		PAYROLL:{
			COMMON:{
				SEARCH: 'Search',
				NEW: 'New',
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				YEAR: ' by Year',
				FILTER: 'Filter',
				GEN: 'Generate',
				OBLIGATORY_FIELD:'There are fields with errors',
				DATE_OUT_RANGE: 'Date out of range',
				DELETE:'Delete',
				Yes:'Yes',
				No:'No',
				COUNT: 'Count book'
			},
			EMPLOYEES:{
				EMPLOYEES_LIST_DRIVERS: 'Employees list (Drivers)',
				EMPLOYEES_LIST_OFFICE: 'Employees list (Office)',
				NEW_EMPLOYEE: 'New',
				EDIT: {
					UPDATE_MESSAGE: 'Employee has been updated',
					ADD_MESSAGE: 'Employee has been created'
				},
				DELETE_EMPLOYEE_SIMPLE: {
					TITLE: 'Employee delete',
					DESCRIPTION: 'Are you sure to permanently delete this employee?',
					WAIT_DESCRIPTION: 'Employee is deleting...',
					MESSAGE: 'Employee has been deleted'
				},

			},
			PAYMENT:{
				REPORT_DRIVERS: 'Payment report (Drivers)',
				REPORT_OFFICE: 'Payment report (Office)',
				CHECK_NUMBER: 'Comments has been updated',
				EDIT_COEFICIENT: 'Coeficient has been updated',
				WEEK_CLOSE: 'Week is close',				
				LOAD_OFFICE_LIST: {
					TITLE: 'Generate officce´s employee list',
					DESCRIPTION: 'There are report in the table that will be replaced. Are you sure to generate list again ?',
					WAIT_DESCRIPTION: 'Generating...',
					MESSAGE: 'Payment report has been generated successfully'
				},	
			},
			WORK:{
				EMPLOYEE_WORK:'Payment Report',	
				EMPLOYEE_WORK_LIST_KEY:'Report List',
				EDIT:{
					UPDATE_MESSAGE: 'Payment report has been updated',
					ADD_MESSAGE: 'Payment report has been created'
				},
				DELETE_WORK_SIMPLE: {
					TITLE: 'Payment report delete',
					DESCRIPTION: 'Are you sure to permanently delete this payment report?',
					WAIT_DESCRIPTION: 'Payment report is deleting...',
					MESSAGE: 'Payment report has been deleted'
				},	
				DELETE_WORK_MULTI:{
					TITLE: 'Selection delete',
					DESCRIPTION: 'Are you sure to permanently delete this selection?',
					WAIT_DESCRIPTION: 'Selection is deleting...',
					MESSAGE: 'Selection has been deleted'
				},
				CLOSE_WEEK: {
					TITLE: 'Close contable week',
					DESCRIPTION: 'Are you sure to close this week?',
					WAIT_DESCRIPTION: 'Closing week...',
					MESSAGE: 'Contable week close'
				},
				CLOSE_WEEK_WARNING: {
					TITLE: 'Close contable week',
					DESCRIPTION: 'There are exceeded deductions. Close week anyway?',
					WAIT_DESCRIPTION: 'Closing week...',
					MESSAGE: 'Contable week close'
				}			
			},
			BONUS:{
				EMPLOYEE_BONUS:'Bonus',
				EDIT:{
					UPDATE_MESSAGE: 'Bonus has been updated',
					ADD_MESSAGE: 'Bonus has been created'
				},
				DELETE_BONUS_SIMPLE: {
					TITLE: 'Bonus delete',
					DESCRIPTION: 'Are you sure to permanently delete this bonus?',
					WAIT_DESCRIPTION: 'Bonus is deleting...',
					MESSAGE: 'Bonus has been deleted'
				},	
			},
			BACKUP:{
				EMPLOYEE_BACKUP:'Backup',
				EDIT:{
					UPDATE_MESSAGE: 'Backup has been updated',
					ADD_MESSAGE: 'Backup has been created'
				},
				DELETE_BACKUP_SIMPLE: {
					TITLE: 'Backup delete',
					DESCRIPTION: 'Are you sure to permanently delete this backup?',
					WAIT_DESCRIPTION: 'Backup is deleting...',
					MESSAGE: 'Backup has been deleted'
				},		
				RESTORE_BACKUP_SIMPLE: {
					TITLE: 'Database Restore',
					DESCRIPTION: 'Are you sure to restore DB throw this backup? This action could perform sensitive changes into DB',
					WAIT_DESCRIPTION: 'Database is restoring...',
					MESSAGE: 'Database has been restored'
				},			
			},
			CREDIT:{
				EMPLOYEE_CREDIT:'Deductions',
				EDIT:{
					UPDATE_MESSAGE: 'Deduction has been updated',
					ADD_MESSAGE: 'Deduction has been created'
				},
				DISABLE_CREDIT_SIMPLE: {
					TITLE: 'Deduction disable',
					DESCRIPTION: 'Are you sure to disable this deduction?',
					WAIT_DESCRIPTION: 'Deduction is disabling...',
					MESSAGE: 'Deduction has been disabled'
				},	
				DELETE_CREDIT_SIMPLE: {
					TITLE: 'Deduction delete',
					DESCRIPTION: 'Are you sure to delete this deduction?',
					WAIT_DESCRIPTION: 'Deduction is deleting...',
					MESSAGE: 'Deduction has been deleted'
				},	
				ENABLE_CREDIT_SIMPLE: {
					TITLE: 'Deduction enable',
					DESCRIPTION: 'Are you sure to enable this deduction?',
					WAIT_DESCRIPTION: 'Proccessing...',
					MESSAGE: 'Deduction has been enabled'
				},	
				INCREMENT_PAY:"Pay incremented"	
			},
			CONCEPTS:{
				LIST: 'Concepts list',
				EDIT:{
					UPDATE_MESSAGE: 'Concept has been updated',
					ADD_MESSAGE: 'Concept has been created'
				},
				DELETE_CONCEPT_SIMPLE: {
					TITLE: 'Concept delete',
					DESCRIPTION: 'Are you sure to permanently delete this concept?',
					WAIT_DESCRIPTION: 'Concept is deleting...',
					MESSAGE: 'Concept has been deleted'
				}
			},
			WEEKS:{
				LIST: 'Weeks contables list',
				EDIT:{
					UPDATE_MESSAGE: 'Contable year has been updated',
					ADD_MESSAGE: 'Contable year has been created'
				},
				DELETE_WEEK_SIMPLE: {
					TITLE: 'Week delete',
					DESCRIPTION: 'Are you sure to permanently delete this week?',
					WAIT_DESCRIPTION: 'Week is deleting...',
					MESSAGE: 'Week has been deleted'
				},
				DELETE_YEAR_SIMPLE: {
					TITLE: 'Countable year delete',
					DESCRIPTION: 'Are you sure to permanently delete this countable year?',
					WAIT_DESCRIPTION: 'Countable year is deleting...',
					MESSAGE: 'Countable year has been deleted'
				}
			}
		},		
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		}
	}
};
