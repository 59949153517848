// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var currentUser = '';
var cUser = '';
if (localStorage.getItem('currentUser') != undefined /*|| localStorage.getItem('currentUser') != 'undefined'*/ || localStorage.getItem('currentUser') != null) {
  // if(localStorage.getItem('currentUser') != null)
  currentUser = localStorage.getItem('currentUser').split('"username":"')[1];
  cUser = currentUser ? currentUser.split('@')[0] : '';
}

export const environment = {
  production: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  host: 'payroll.xprad.com',
  // host: 'localhost',
  port: 8000,
  protocol: 'https',
  url: 'https://routexy.xprad.com:8443/signin',
  // url: 'https://192.168.43.133:8443/signin',
  user: cUser
};
