import { AclModel } from '../models/acl';
import { Injectable } from '@angular/core';
import { ConfigData } from '../interfaces/config-data';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { from, BehaviorSubject, Subject } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class AclService implements ConfigData {
	public aclModel: AclModel;
	public onAclUpdated$: BehaviorSubject<AclModel>;

	constructor(
		private roleService: NgxRolesService,
		private permService: NgxPermissionsService,
		private authService: AuthenticationService,
	) {
		// set initial permission model
		this.aclModel = new AclModel();
		this.onAclUpdated$ = new BehaviorSubject(this.aclModel);

		this.authService.getUserRoles().subscribe(roles => {
			console.log('acl 1', roles);
			this.setCurrrentUserRoles(roles);
		});

		// subscribe to credential changed, eg. after login response
		this.authService.onCredentialUpdated$
			.pipe(mergeMap(accessData => this.authService.getUserRoles()))
			.subscribe(roles => {
				console.log('acl 2', roles);
				this.setCurrrentUserRoles(roles)
			});

		// subscribe to acl data observable
		this.onAclUpdated$.subscribe(acl => {
			console.log('acl this.onAclUpdated$', acl);

			const permissions = Object.keys(acl.permissions).map((key) => {
				return acl.permissions[key];
			});
			console.log('permissions', permissions);
			this.permService.loadPermissions(permissions, (permissionName, permissionStore) => !!permissionStore[permissionName]);

			const roles = Object.assign({}, this.aclModel.currentUserRoles, {
				GUEST: () => {
				}
			});

			this.roleService.addRoles(roles);
		});
	}

	/**
	 * Set AclModel and fire off event that all subscribers will listen to
	 * @param aclModel
	 */
	setModel(aclModel: AclModel): any {
		console.log('setModel aclModel 1: ', aclModel)
		console.log('setModel aclModel 2: ', this.aclModel)
		aclModel = Object.assign({}, this.aclModel, aclModel);
		this.onAclUpdated$.next(aclModel);
	}

	setCurrrentUserRoles(roles: any): any {
		// update roles if the credential data has roles
		console.log('setCurrrentUserRoles roles: ', roles);
		console.log('localStorage.getItem(currentUser)',localStorage.getItem('currentUser'));
		if(localStorage.getItem('currentUser') != 'undefined'){
			if (roles != null) {
				let myObject = {};
				let permisos: any = JSON.parse(localStorage.getItem('currentUser'));
				// this.aclModel.currentUserRoles = {};
				console.log('permisos setCurrrentUserRoles', permisos.permissions);
				roles.forEach(role => {
					myObject[role] = permisos.permissions.split(',');
					// this.aclModel.currentUserRoles[role] = this.aclModel.permissions[role];
				});
				console.log('myObject: ', myObject)
				this.AsignarRolyPermisos(permisos.permissions.split(','), myObject)
				// set updated acl model back to service
				console.log('setModel aclModel 0: ', this.aclModel);
				let a: any = {
					permissions: myObject,
					currentUserRoles: {}
				}
				this.setModel(a);
			}
		}
	}
	// ---------------------------------------------------------------------------------------------
	AsignarRolyPermisos(permisos: any, current: object) {
		console.log('AsignarRolyPermisos permisos: ', permisos);
		console.log('AsignarRolyPermisos current: ', current);
		this.permService.loadPermissions(permisos);
		// this.permService.addPermission(permisos)

		this.permService.permissions$.subscribe((permissions) => {
			console.log('this.permService.permissions$: ', permissions)
		});
		const roles = Object.assign({}, current, {
			// default user role is GUEST
			GUEST: () => {
				// return this.authService.isAuthorized().toPromise();
			}
		});
		// add to role service
		console.log('add to role service: ', roles)
		this.roleService.addRoles(roles);
	}
}
