import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { LayoutUtilsService, MessageType } from '../utils/layout-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { TypesUtilsService } from '../utils/types-utils.service';
import { ActualUserService } from '../services/user.service';
import { passwordValidator } from "../models/password.validator";
import { LoginService } from '../services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'm-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {

  changePassForm: FormGroup;
  hasFormErrors: boolean = false;
	viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;

  fields = {
    user: '',
    oldPass: '',
    newPass: '',
    confirmNewPass: '',
  }

  invalidPass: boolean;
  invalidPassLength:boolean;
  oldPassWrong: boolean;

  constructor(private loginService: LoginService,
    public dialogRef: MatDialogRef<ChangePassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private typesUtilsService: TypesUtilsService,
    private detRef: ChangeDetectorRef,
		private authService: ActualUserService) { }

  ngOnInit() {
    this.fields.user = this.data.user.username;
    this.createForm();
  }

  createForm() {    
    this.changePassForm = this.fb.group({
      old_pass: ['', Validators.required],
      new_pass: ['', Validators.required],
      confirm_new_pass: ['', Validators.required]
    });	 
  }


  /** UI */
  getTitle(): string {  
    return `Change Password`;  
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.changePassForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  /** ACTIONS */
  prepareForm() {
    const controls = this.changePassForm.controls;
    this.fields.oldPass = controls['old_pass'].value;
    this.fields.newPass = controls['new_pass'].value;
    this.fields.confirmNewPass = controls['confirm_new_pass'].value;
  }

  
onSubmit() {
  this.prepareForm(); 
  this.hasFormErrors = false;
  this.loadingAfterSubmit = false;
  const controls = this.changePassForm.controls;
  if (this.changePassForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    return;
  }
  if(this.fields.newPass != this.fields.confirmNewPass){
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );
    this.invalidPass = true;
    this.hasFormErrors = true;
    return;
  }
  if(this.fields.newPass.length < 8){
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );
    this.invalidPassLength = true;
    this.hasFormErrors = true;
    return;
  }
  if (this.changePassForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    return;
  }

  this.invalidPassLength = false;
  this.invalidPass = false;
  this.changePass();
  
}

changePass() {
  localStorage.clear();

  this.loginService.login(this.data.user.username, this.fields.oldPass).subscribe(r => {console.log('######RRR#####',r);
    if(r != 'undefined'){
      this.loadingAfterSubmit = true;
      this.viewLoading = true;
      this.authService.changePass(this.fields).subscribe(res => {   		
        this.viewLoading = false;  
        this.dialogRef.close({
          res: res['text']
        });  
      });
    } 
    // window.location.reload();
  },
  (error: HttpErrorResponse) => {      
    if (error.statusText == "Unauthorized") {
      this.oldPassWrong = true; 
      this.detRef.detectChanges();
      // // this.changePassForm.reset();
    }
    console.error('QUE VUELTA',error);
  });
}

onAlertClose($event) {
  this.hasFormErrors = false;
}

close(){
  this.dialogRef.close()
}

  
}



