// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			REPORTS: 'Reportes',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero',
			EMPLOYEES: 'Empleados',
			PAYMENT:'Reporte de pago',
			SETTINGS: 'Configuración',
			COUNT:'Submayor',
			BACKUP:'Salvar y Restaurar',			
			PAYMENT_REPORT: 'Resúmen de Reporte de Pago',
			USER_LOG: 'Trazas de Usuarios',
			EMPLOYEE_CREDITS: 'Deudas de Empeados',
			CREDITS_CONCEPT: 'Deudas por Concepto'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Se te olvidó tu contraseña',
				BACK_BUTTON: 'Espalda',
				PRIVACY: 'Intimidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Crear una cuenta',
				BUTTON: 'Registrarse',
			},
			FORGOT: {
				TITLE: 'Contraseña olvidada?',
				DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect'
			}
		},
		PAYROLL:{
			COMMON:{
				SEARCH: 'Buscar',
				NEW: 'Nuevo',
				SELECTED_RECORDS_COUNT: 'Cant. registros seleccionados: ',
				YEAR: ' por Año',
				FILTER: 'Filtro',
				GEN: 'Generar',
				OBLIGATORY_FIELD:'Existen campos con errores',
				DATE_OUT_RANGE: 'Fecha fuera de rango',
				DELETE:'Borrar',
				Yes:'Si',
				No:'No',
				COUNT: 'Libro submayor'
			},
			EMPLOYEES:{
				EMPLOYEES_LIST_DRIVERS: 'Lista de empleados (Choferes)',
				EMPLOYEES_LIST_OFFICE: 'Lista de empleados (Oficinistas)',
				NEW_EMPLOYEE: 'Nuevo'
			},
			EDIT: {
				UPDATE_MESSAGE: 'El empleado ha sido actualizado',
				ADD_MESSAGE: 'El empleado ha sido adicionado'
			},
			DELETE_EMPLOYEE_SIMPLE: {
				TITLE: 'Empleado eliminado',
				DESCRIPTION: '¿Está seguro de querer eliminar este empleado?',
				WAIT_DESCRIPTION: 'El empleado está siendo eliminado...',
				MESSAGE: 'Empleado eliminado satisfactoriamente'
			},
			PAYMENT:{
				REPORT_DRIVERS: 'Reporte de pago (Choferes)',
				REPORT_OFFICE: 'Reporte de pago (Oficinistas)',
				CHECK_NUMBER: 'El número de cheque ha sido actualizado',
				EDIT_COEFICIENT: 'El coeficiente ha sido actualizado',
				WEEK_CLOSE: 'La semana está cerrada'
			},
			WORK:{
				EMPLOYEE_WORK:'Reporte de pago',
				EMPLOYEE_WORK_LIST_KEY:'Lista de reportes',
				EDIT:{
					UPDATE_MESSAGE: 'El reporte de pago ha sido actualizado',
					ADD_MESSAGE: 'El reporte de pago ha sido creado'
				},
				DELETE_WORK_SIMPLE: {
					TITLE: 'Reporte de pago eliminado',
					DESCRIPTION: '¿Está seguro de querer eliminar este reporte de pago?',
					WAIT_DESCRIPTION: 'Eliminando reporte de pago...',
					MESSAGE: 'Reporte de pago eliminado'
				},
				DELETE_WORK_MULTI:{
					TITLE: 'Selección eliminada',
					DESCRIPTION: '¿Está seguro de querer eliminar esta selección?',
					WAIT_DESCRIPTION: 'Eliminando selección...',
					MESSAGE: 'Selección eliminada'
				},	
				CLOSE_WEEK: {
					TITLE: 'Cerrar semana contable',
					DESCRIPTION: '¿Está seguro de cerrar esta semana?',
					WAIT_DESCRIPTION: 'Cerrando semana...',
					MESSAGE: 'Semana contable cerrada'
				}					
			},
			BONUS:{
				EMPLOYEE_BONUS:'Bonos',
				EDIT:{
					UPDATE_MESSAGE: 'El bono ha sido actualizado',
					ADD_MESSAGE: 'El bono ha sido creado'
				},
				DELETE_BONUS_SIMPLE: {
					TITLE: 'Bono eliminado',
					DESCRIPTION: '¿Está seguro de querer eliminar este bono?',
					WAIT_DESCRIPTION: 'Eliminando bono...',
					MESSAGE: 'Bono eliminado'
				},		
			},
			CREDIT:{
				EMPLOYEE_CREDIT:'Deducciones',
				EDIT:{
					UPDATE_MESSAGE: 'La deducción ha sido actualizada',
					ADD_MESSAGE: 'La deducción ha sido creada'
				},
				DELETE_CREDIT_SIMPLE: {
					TITLE: 'Deducción eliminada',
					DESCRIPTION: '¿Está seguro de querer eliminar esta deducción?',
					WAIT_DESCRIPTION: 'Eliminando deducción...',
					MESSAGE: 'Deducción eliminada'
				},
				INCREMENT_PAY:"Pago incrementado"			
			},
			CONCEPTS:{
				LIST: 'Listado de conceptos',
				EDIT:{
					UPDATE_MESSAGE: 'El concepto ha sido actualizado',
					ADD_MESSAGE: 'El concepto ha sido creado'
				},
				DELETE_CONCEPT_SIMPLE: {
					TITLE: 'Concepto eliminado',
					DESCRIPTION: '¿Está seguro de querer eliminar este concepto?',
					WAIT_DESCRIPTION: 'Eliminando concepto...',
					MESSAGE: 'Concepto eliminado'
				}
			},
			WEEKS:{
				LIST: 'Lista de semanas contables',
				EDIT:{
					UPDATE_MESSAGE: 'El año contable ha sido actualizado',
					ADD_MESSAGE: 'El año contable ha sido creado'
				},
				DELETE_WEEK_SIMPLE: {
					TITLE: 'Semana borrada',
					DESCRIPTION: '¿Está seguro de borrar esta semana?',
					WAIT_DESCRIPTION: 'Semana borrándose...',
					MESSAGE: 'Semana borrada'
				},
				DELETE_YEAR_SIMPLE: {
					TITLE: 'Eliminar año contable',
					DESCRIPTION: '¿Está seguro de eliminar el año contable?',
					WAIT_DESCRIPTION: 'Eliminando año contable...',
					MESSAGE: 'El año contable ha sido eliminado'
				}
			}
		},		
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		}
	}
};
