import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs/';
import { mergeMap } from 'rxjs/operators';
import { WorkReportModel } from '../models/work-report.model';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../utils/http-utils.service';
import { QueryParamsModel } from '../query-models/query-params.model';
import { QueryResultsModel } from '../query-models/query-results.model';
import { environment } from '../../../../../environments/environment';


const API_WORK_URL = environment.protocol +'://'+environment.host+':'+environment.port+'/payroll';
const cUser = environment.user;



@Injectable({
  providedIn: 'root'
})
export class WorkReportService {

  constructor(private http: HttpClient,private httpUtils: HttpUtilsService) { }

  // printUserLog(): Observable<QueryResultsModel> {
  //   let url = API_WORK_URL+'/print_user_log';
  //   console.log('userService',url);
  //   return this.http.get<any>(url);
  // }

  getWorkEmployee(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
     // Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    let url = API_WORK_URL+'/work'
    
    return this.http.get<any>(url,{
      headers: httpHeaders,
			params:  httpParams
    }).pipe(
			mergeMap(res => {
        const queryResults = new QueryResultsModel();
        queryResults.items = res.items;   
        queryResults.weekClose = res.weekClose;    
				return of(queryResults);
			})
		);
  }

  getWorkEmployeeByDate(queryParams: QueryParamsModel): Observable<QueryResultsModel>{
     // Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    let url = API_WORK_URL+'/work/bydate'
    
    return this.http.get<any>(url,{
      headers: httpHeaders,
			params:  httpParams
    }).pipe(
			mergeMap(res => {
        const queryResults = new QueryResultsModel();
        queryResults.items = res.items;   
        queryResults.weekClose = res.weekClose;    
				return of(queryResults);
			})
		);
  }

  // CREATE =>  POST: add a new work to an employee to the server
	createWorkEmployee(workReport: WorkReportModel): Observable<WorkReportModel> {
		// Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    let url = API_WORK_URL+'/work';
    workReport.user = cUser;
		return this.http.post<WorkReportModel>(url, workReport, { headers: httpHeaders});
  }

  deleteWorkEmployee(idWork: number): Observable<WorkReportModel> {
    let url = API_WORK_URL+'/work';
		url = `${url}/${idWork}/${cUser}`;
		return this.http.delete<any>(url);
  }

  deleteWorkEmployeeSelected(idWork: string): Observable<WorkReportModel> {
    let url = API_WORK_URL+'/work/selection/' + cUser;
    const httpHeaders = this.httpUtils.getHTTPHeaders();
		
		return this.http.post<any>(url,{cadena:idWork},{ headers: httpHeaders});
  }
  
   // UPDATE => PUT: update the employee on the server
	updateWorkEmployee(work: WorkReportModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let url = API_WORK_URL+'/work/' + cUser;
		return this.http.put(url, work, { headers: httpHeader });
  }

  // Cerrar semana contable
  closeWeek(idWeek: number){
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let url = API_WORK_URL+'/work/closeweek';
		url = `${url}/${idWeek}/${cUser}`;
		return this.http.post<any>(url,{}, { headers: httpHeader });
  } 

  getDataChartEmployee(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
   const httpHeaders = this.httpUtils.getHTTPHeaders();
   const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
   let url = API_WORK_URL+'/work/databaremployees/3'
   
   return this.http.get<any>(url,{
     headers: httpHeaders,
     params:  httpParams
   }).pipe(
     mergeMap(res => {      
       const queryResults = new QueryResultsModel();
       queryResults.items = res.items;
       return of(queryResults);
     })
   );
 }
}
