import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../../../../pages/components/login/login.component';
import { ActualUserService } from '../../../../pages/payroll/services/user.service';
import { WeekService } from '../../../../pages/payroll/services/week.service';
import { LayoutUtilsService, MessageType } from '../../../../pages/payroll/utils/layout-utils.service';
import { ChangePassComponent } from '../../../../pages/payroll/change-pass/change-pass.component';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/4K (30).jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	curUser: any;

	constructor (
		private router: Router,
		private authService: ActualUserService,
		private sanitizer: DomSanitizer,
		public dialog: MatDialog,
		private weekService: WeekService,
		private layoutUtilsService: LayoutUtilsService
	) {}

	ngOnInit (): void {
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
		}
		this.getCurUser();
	}

	getCurUser(){
		this.curUser = this.authService.getUserLoggedIn();
	}

	public logout () {
		localStorage.clear();
		// this.weekService.generateAllWeeksOff().subscribe(res => {
		this.authService.logout().subscribe(r => {
			location.href = '/payroll';
		});
		// });
	}

	changePass(){
		const dialogRef = this.dialog.open(ChangePassComponent, { width:'400px', data: { 'user': this.curUser } });
		dialogRef.afterClosed().subscribe(res => { console.log('res....................',res);    
		  if (!res) {
			return;
		  }else{
				this.layoutUtilsService.showActionNotification(
					res.res, 
					MessageType.Update, 
					5000, 
					true, 
					false
				);				  
			  setTimeout(() => {
				window.location.reload();
			  }, 5000);
		  } 
		});
	}
}