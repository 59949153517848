export class QueryResultsModel {
	// fields
	items: any[];
	totalCount: number;
	currentYear: number;	
	currentWeek: number;
	weekClose: number;
	errorMessage: string;

	constructor(_items: any[] = [], _errorMessage: string = '',_currentWeek: number = 0,_weekClose:number = 0) {
		this.items = _items;
		this.totalCount = _items.length;
		let now = new Date();
		let currentYear = now.getFullYear();  
		this.currentYear = currentYear;
		this.currentWeek = _currentWeek;
		this.weekClose = _weekClose;
	}
}
