import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of,BehaviorSubject } from 'rxjs/';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { ActualUserService } from './user.service';


@Injectable()
export class PayrollNgxService {

  constructor(
    private http: HttpClient,
    private roleService: NgxRolesService,
    private perm: NgxPermissionsService,
    private userService: ActualUserService,
  ) {}

  testingNgxPermissions(){
    let perm = this.userService.getUserLoggedIn()['permissions'];
    let a = ['superadmin'];
    this.perm.loadPermissions(a);
    this.roleService.addRole('superadmin',a);
  }
}