import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpUtilsService } from '../utils/http-utils.service';


const API_USER_URL = environment.protocol + '://' + environment.host + ':' + environment.port + '/payroll';
const cUser = environment.user;

@Injectable()
export class ActualUserService {

  private isUserLoggedIn;
  public usserLogged: User;

  constructor(private http: HttpClient,private httpUtils: HttpUtilsService) {
    this.isUserLoggedIn = false;
  }

  authorizedUser() {
    return this.http.get(API_USER_URL + '/authorized_user');
  }

  nodeServerAuth(username)/*: Observable<any>*/ {
    console.log('this.http.get(API_USER_URL', username)
    return this.http.put(`${API_USER_URL}/authorized_user/${username}`,username);
  }


  setUserLoggedIn(user: User) {    
    const httpHeader = this.httpUtils.getHTTPHeaders();
    this.isUserLoggedIn = true;
    this.usserLogged = user;
    localStorage.setItem('allWeeks', 'false');

    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('permissions', JSON.stringify(user.permissions));
    // let id = user.id;
    // console.log('id: ', id);
    // console.log(`AAAAAAAAAAAHHHHHHHHHHHHHH---${API_USER_URL}/authorized_user/${id}`)
    // return this.http.get(`${API_USER_URL}/authorized_user/${environment.user}`);
  }

  getUserLoggedIn() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  // setLastUrl(url: String){console.log('HERE',url,API_USER_URL + '/last_url');
  //   const httpHeaders = this.httpUtils.getHTTPHeaders();
  //   return this.http.post(API_USER_URL + '/last_url',url,{headers: httpHeaders});
  // }

  setLastUrl(url: String){console.log('HERE',url,API_USER_URL + '/last_url/' + '***' + url.split('/')[1]);
    return this.http.get(API_USER_URL + '/last_url/' + '***' + url.split('/')[1]);
  }

  logout() {
    // this.setLastUrl(url/*,userId*/);
    // localStorage.
    localStorage.clear();
    return this.http.get(API_USER_URL + '/nodeserver_logout/' + cUser);
  }

  changePass(data){
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(API_USER_URL + '/change_password',data, { headers: httpHeader });
  }

}