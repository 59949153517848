import { Component, OnInit } from '@angular/core';
import { WorkReportService } from '../../services/work-report.service';
import { QueryParamsModel } from '../../query-models/query-params.model';

@Component({
  selector: 'm-bar-chart-employee',
  templateUrl: './bar-chart-employee.component.html',
  styleUrls: ['./bar-chart-employee.component.scss']
})
export class BarChartEmployeeComponent implements OnInit {

  public barChartOptions: any = {
		scaleShowVerticalLines: true,
		responsive: true
	};
  public barChartLabels: string[] = [];  
	public barChartType: string = 'bar';
	public barChartLegend: boolean = true;

	public barChartData: any[]=[{data:[],label:''}];

  constructor(private workReport: WorkReportService) { 
    
  }

  ngOnInit() {
    this.workReport.getDataChartEmployee(new QueryParamsModel(null,'','',0,0)).subscribe(res => {
        let cant = res.items.length;     
        for(let i = 0; i < cant; i++){
          this.barChartLabels.push(res.items[i].worker.toString());          
          this.barChartData[0].data.push(res.items[i].milles);
        }       
        this.barChartData[0].label = 'Milles';
    });
  }

  // events
	chartClicked (e: any): void {
	}

	chartHovered (e: any): void {
	}

}
