import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,of,BehaviorSubject } from 'rxjs/';
import { environment } from '../../../../../environments/environment';
import { HttpUtilsService } from '../utils/http-utils.service';



const API_URL = environment.protocol + '://' + environment.host + ':' + environment.port + '/payroll';
@Injectable()
export class LoginService {

  constructor(private http: HttpClient,private httpUtils: HttpUtilsService) {
  }

  spinner = new BehaviorSubject<boolean>(false);
  currentSpinner = this.spinner.asObservable();

  disableSpinner(value: boolean){
    this.spinner.next(value);
  }

  login(username:string, password:string) {
    //return this.http.post('https://reqres.in/api/login', {
    // const httpHeaders = this.httpUtils.getHTTPHeaders();
    let reqHeader = new HttpHeaders().set('Content-Type', 'application/json');
    let url = API_URL + '/login' // environment.url;    
    this.disableSpinner(true);
    let result = this.http.post(url, {email: username, password: password},{ headers: reqHeader}); 
    console.log('result',result);
    result.subscribe(r => {console.log('rrrr',r);})
    return result;  
  }

  // logout(){
  //   return this.http.get(API_URL + '/nodeserver_logout');
  // }
}